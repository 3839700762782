@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --pf-primary-color: #aec1cf;
  --pf-secondary-color: #101928;
  --pf-background-color: #cddae3;
  --pf-accent: #f5f5f5;
  --pf-text-primary: #053c5e;
  --pf-error-light: #e57373;
  --pf-error-primary: #f44336;
  --pf-box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);
  --chat-scrollbar-width: 5px;
}

@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
    }
  }