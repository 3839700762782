button:active {
  transform: scale(0.95);
  box-shadow: inset 20px 20px 60px #043350,
              inset -20px -20px 60px #06456c;
}

.ChatRoom_container___open {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 4rem;
  right: 2rem;
  height: 500px;
  max-width: 400px;
  width: 23rem;
  border-radius: 0.5rem;
  border: solid 1px;
  border-color: var(--pf-text-primary);
  background-color: white;
  z-index: 3;
  box-shadow: var(--pf-box-shadow);
  transition: all 250ms ease-out;
}

.ChatRoom_container___close {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  bottom: 1rem;
  right: 1rem;
  z-index: 3;
  background-color: var(--pf-text-primary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 175px;
  transition: all 250ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ChatRoom_form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 500px;
  width: 100%;
}

.ChatRoom_inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ChatRoom_loginHeader {
  position: absolute;
  top: 0;
  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--pf-primary-color);
  width: 100%;
  height: 120px;
}

.ChatRoom_loginFooter {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  bottom: 0;
}

.ChatRoom_loginNotice {
  text-align: center;
  color: var(--pf-error-light);
}

.ChatRoom_button___login {
  border: 1px solid var(--pf-secondary-color);
  border-radius: 0.25rem;
  font-weight: bold;
  color: var(--pf-text-primary);
  background-color: transparent;
  padding: 8px 20px;
  margin: 40px
}

.ChatRoom_button___login:hover {
  background-color: var(--pf-text-primary);
  color: var(--pf-accent);
}

.ChatRoom_button___open {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 5;
  background-color: var(--pf-text-primary);
  color: white;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 50px;
  width: 175px;
  transition: ease-in 150ms;
}

.ChatRoom_button___open:hover {
  font-weight: bold;
  transform: scale(1.05);
}

.ChatRoom_button___close {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 2;
  background-color: var(--pf-text-primary);
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  transition: ease-out 150ms;
}

.ChatRoom_button___close:hover {
  transform: scale(1.15);
}
